import React, { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import './styles.scss';


const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};


export default function TermsConditionDialog (props) {

  const {
    pdfURL,
    handleDialogClose,
    selectedTC
  } = props;
    
  return (
    <dialog id='terms-condition-dialog'>
      <div className="dialog-header">
        <h3>{selectedTC}</h3>
        <button className="dialog-toggle-btn" onClick={handleDialogClose}> ✕ </button>
      </div>
      <div className="bsl-dialog-content">
        {
          pdfURL &&
                    <PDFContainer pdfURL={pdfURL} />
        }
      </div>
    </dialog>
  );

}

function PDFContainer({pdfURL}) {
  // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  if (typeof window !== 'undefined' && 'Worker' in window) {
    pdfjs.GlobalWorkerOptions.workerSrc = new URL(
      'pdfjs-dist/build/pdf.worker.min.js',
      import.meta.url,
    ).toString();
  }

  const [numPages, setNumPages] = useState();

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  return (
    // <div className="Example">
    <div className="Example__container">
      <div className="Example__container__document">
        <Document loading={"Loading PDF…"} file={pdfURL} onLoadSuccess={onDocumentLoadSuccess} options={options}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page 
              scale={2} 
              key={`page_${index + 1}`} 
              pageNumber={index + 1} 
              width={800}
            />
          ))}
        </Document>
      </div>
    </div>
    // </div>
  );
}