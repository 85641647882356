import React from "react";

import Layout from "../../components/Layout/layout";
import TermConditions from "../../components/TermsCondition";

const TermConditionsPage = () => {
  return (
    <Layout>
      <TermConditions />
    </Layout>
  );
};

export default TermConditionsPage;